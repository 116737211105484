import { RiFileList2Line } from "react-icons/ri";
import { RiHome6Line } from "react-icons/ri";
import { ImSphere } from "react-icons/im";
import { BsThreeDots } from "react-icons/bs";

export function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        height: "8vh",
        position: "fixed",
        // left: '-0.3vw',
        left: "0",
        bottom: "0",
        border: "2px solid",
        borderBottom: "none",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "rgba(29, 27, 27, 0.6)",
        boxSizing: "border-box",
      }}
    >
      <RiFileList2Line size={35} style={{ margin: "10px" }} />
      <RiHome6Line size={35} color="#34ABCB" style={{ margin: "10px" }} />
      <ImSphere size={35} style={{ margin: "10px" }} />
      <BsThreeDots size={35} style={{ margin: "10px" }} />
    </footer>
  );
}
