import { useNavigate } from "react-router-dom";

export function Auth() {
  const navigate = useNavigate();
  function Validate() {
    document.getElementById("input").value === "888"
      ? navigate("mainpage")
      : (document.getElementById("input").style.background = "red");
  }
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        id="input"
        type="password"
        style={{ width: "300px", height: "50px" }}
      />
      <button
        onClick={() => Validate()}
        style={{
          width: "200px",
          height: "40px",
          margin: "30px",
        }}
      >
        Click
      </button>
    </div>
  );
}
