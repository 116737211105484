import "./MainPage.css";
import { Header } from "./Components/Header";
import { Main } from "./Components/Main";
import { Footer } from "./Components/Footer";
import { useEffect } from "react";

const Telegram = window.Telegram.WebApp;

export function MainPage() {
  useEffect(() => {
    Telegram.ready();
    Telegram.expand();
    Telegram.lockOrientation();
    Telegram.disableVerticalSwipes();
    // telegram.LocationManager.init();
  }, []);

  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
