import cup from "../images/Vector.svg";
import qrLogo from "../images/qr-logo.png";
import { Avatar } from "@telegram-apps/telegram-ui";
import { useState, useEffect, useRef } from "react";

const telegram = window.Telegram.WebApp;

export function Main() {
  const [photoUrl, setPhotoUrl] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const dialogRef = useRef(null);

  useEffect(() => {
    setPhotoUrl(telegram.initDataUnsafe.user.photo_url);
    setUserFirstName(telegram.initDataUnsafe.user.first_name);
    telegram.LocationManager.init();
    telegram.SettingsButton.show();
    telegram.onEvent('settingsButtonClicked', () => {
      telegram.LocationManager.openSettings();
    })
  }, [photoUrl]);

  function ShowQr() {
    document.getElementById("qr").classList.add("qr-disappear");
    dialogRef.current.showModal();
  }

  return (
    <main>
      {/* <div>{telegram.SettingsButton.show()}</div> */}
      {/* <button onClick={() => telegram.LocationManager.openSettings()}>
      </button> */}
      {/* <button onClick={() => telegram.SettingsButton.hide()}> close but set</button> */}
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar size={60} src={photoUrl} />
          <p style={{ display: "inline", margin: "10px" }}>
            Привет, <br /> {userFirstName}!
          </p>
        </div>
        <div>
          <button
            style={{
              width: "111px",
              height: "50px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: "15px",
              border: "1px solid",
              backgroundColor: "#34ABCB",
            }}
          >
            10 000 <img src={cup} alt="cup" />
          </button>
          <dialog ref={dialogRef}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={qrLogo} alt="qrLogo" />
              <button
                onClick={() => {
                  dialogRef.current.close();
                  document
                    .getElementById("qr")
                    .classList.remove("qr-disappear");
                }}
              >
                close
              </button>
            </div>
          </dialog>
        </div>
      </section>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0 30px 0",
        }}
      >
        <div style={{ width: "50%" }}>
          <p style={{ display: "inline" }}>
            <em>Самое время выпить чашечку кофе!</em>
          </p>
        </div>
        <div>
          <button
            id="qr"
            className="default-qr-button"
            onClick={ShowQr}
            style={{
              position: "absolute",
              right: "0",
              width: "144px",
              height: "70px",
              margin: "-34px 0",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
              border: "1px solid",
              borderRight: "none",
              backgroundColor: "#ECECEC",
            }}
          >
            Показать <br />
            QR-код
            <img src={qrLogo} alt="qr logo" style={{ maxWidth: "40px" }} />
          </button>
        </div>
      </section>
      <section
        style={{
          display: "flex",
          width: "355px",
          flexDirection: "column",
          border: "1px solid",
          borderRadius: "30px",
          margin: "45px auto 25px auto",
        }}
      >
        <div>
          <p style={{ margin: "20px" }}>Как работает система лояльности?</p>
          <p style={{ margin: "20px" }}>
            <em>1 покупка = 1 стаканчик</em>
          </p>
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            gap: "10px",
            margin: "20px",
          }}
        >
          <div
            className="card"
            style={{
              display: "flex",
              flex: "0 0 200px",
              flexDirection: "column",
              border: "1px solid",
              borderRadius: "15px",
              width: "auto",
              backgroundColor: "#ECECEC",
            }}
          >
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p>
          </div>
          <div
            className="card"
            style={{
              display: "flex",
              flex: "0 0 200px",
              flexDirection: "column",
              border: "1px solid",
              borderRadius: "15px",
              width: "auto",
              backgroundColor: "#ECECEC",
            }}
          >
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p>
          </div>
          <div
            className="card"
            style={{
              display: "flex",
              flex: "0 0 200px",
              flexDirection: "column",
              border: "1px solid",
              borderRadius: "15px",
              width: "auto",
              backgroundColor: "#ECECEC",
            }}
          >
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p>
          </div>
        </div>
      </section>
      <section
        style={{
          width: "355px",
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px auto",
        }}
      >
        <button
          style={{
            width: "170px",
            height: "50px",
            border: "1px solid",
            borderRadius: "6px",
            borderTopLeftRadius: "15px",
            backgroundColor: "#ECECEC",
          }}
        >
          Мероприятия
        </button>
        <button
          style={{
            width: "170px",
            height: "50px",
            border: "1px solid ",
            borderRadius: "6px",
            borderTopRightRadius: "15px",
            backgroundColor: "#ECECEC",
          }}
        >
          Новинки
        </button>
        <button
          style={{
            width: "170px",
            height: "50px",
            border: "1px solid ",
            borderRadius: "6px",
            borderBottomLeftRadius: "15px",
            backgroundColor: "#ECECEC",
          }}
        >
          Новости
        </button>
        <button
          style={{
            width: "170px",
            height: "50px",
            border: "1px solid ",
            borderRadius: "6px",
            borderBottomRightRadius: "15px",
            backgroundColor: "#ECECEC",
          }}
        >
          Акции
        </button>
      </section>
      <div style={{ width: "100%", height: "50px" }}></div>
    </main>
  );
}
