import "@telegram-apps/telegram-ui/dist/styles.css";
import { Routes, Route } from "react-router-dom";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { Auth } from "./Auth";
import { MainPage } from "./MainPage/MainPage";

function App() {
  return (
    <AppRoot>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/mainpage" element={<MainPage />} />
      </Routes>
    </AppRoot>
  );
}

export default App;
