import logo from "../images/logo.png";
import "./Header.css";

export function Header() {
  return (
    <header className="header">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            maxWidth: "1.875rem",
            borderRadius: "50%",
            margin: "10px",
          }}
        />
        <p style={{ display: "inline" }}>GOOSE</p>
      </div>
    </header>
  );
}
